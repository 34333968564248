/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import metricsAPI from '../clientweb/clientwebapi'

export const SLICE_NAME = 'clientWebLogsMetrics'

export const clientWebLogsMetricsUrls = createAsyncThunk(
  'clientWebLogsMetrics/signedUrls',
  async (...[, thunkAPI]) => {
    const { signal } = thunkAPI
    try {
      const response = await metricsAPI.getClientWebLogsSignedUrls({
        signal,
      })
      // console.log(response)
      return response
    } catch (err) {
      console.error(err)
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)

export const getClientWebLogMetrics = createAsyncThunk(
  'clientWebLogsMetrics/data',
  async ({ url, metric }, thunkAPI) => {
    const { signal } = thunkAPI
    try {
      console.log('모두 조회요청', metric)
      // console.log('2. 데이터 가져오기, ' + url)
      const response = await metricsAPI.getClientWebLogFile(url, { signal })
      return response
    } catch (err) {
      console.error(err)
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)

export const getTenantClientWebLogs = createAsyncThunk(
  'clientWebLogsMetrics/tenants',
  async ({ metric, timeRange, tenantId }, thunkAPI) => {
    const { signal } = thunkAPI
    try {
      console.log('테넌트별 조회요청', metric, timeRange, tenantId)
      const response = await metricsAPI.getClientWebLogsByTenant(
        metric,
        timeRange,
        tenantId,
        {
          signal,
        }
      )
      return response
    } catch (err) {
      console.error(err)
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)

const clientWebLogsMetricsSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    urls: {},
    loading: 'idle',
    error: null,
    data: {},
    dataCount: {},
    dataPathCount: {},
    dataList: {},
    dataPathList: {},
    dataCSBytes: {},
    dataPathCSBytes: {},
    dataSCBytes: {},
    dataPathSCBytes: {},
  },
  reducers: {},
  extraReducers: {
    [clientWebLogsMetricsUrls.fulfilled]: (state, action) => {
      const urls = action.payload
      if (urls?.length > 0) {
        urls.forEach((url) => {
          const name = Object.keys(url).shift()
          const value = url[name]
          state.urls[name] = value
        })
      } else {
        state.urls = {}
      }
      state.loading = 'idle'
      state.error = null
      return state
    },
    [clientWebLogsMetricsUrls.pending]: (state, action) => {
      state.loading = 'pending'
      state.error = null
    },
    [clientWebLogsMetricsUrls.rejected]: (state, action) => {
      state.loading = 'idle'
      state.urls = {}
      state.error = action.payload
    },
    [getClientWebLogMetrics.fulfilled]: (state, action) => {
      const { meta } = action
      const { arg } = meta
      const { metric } = arg

      state.loading = 'idle'
      if (metric === 'REQUEST_COUNT') {
        state.dataCount[metric] = action.payload
      } else if (metric === 'PATH_REQUEST_COUNT') {
        state.dataPathCount[metric] = action.payload
      } else if (metric === 'REQUEST_LIST') {
        state.dataList[metric] = action.payload
      } else if (metric === 'PATH_REQUEST_LIST') {
        state.dataPathList[metric] = action.payload
      } else if (metric === 'REQUEST_CSBYTES') {
        state.dataCSBytes[metric] = action.payload
      } else if (metric === 'PATH_REQUEST_CSBYTES') {
        state.dataPathCSBytes[metric] = action.payload
      } else if (metric === 'REQUEST_SCBYTES') {
        state.dataSCBytes[metric] = action.payload
      } else if (metric === 'PATH_REQUEST_SCBYTES') {
        state.dataPathSCBytes[metric] = action.payload
      } else {
        state.data[metric] = action.payload
      }

      return state
    },
    [getClientWebLogMetrics.pending]: (state, action) => {
      state.loading = 'pending'
      if (action.meta.arg.metric === 'REQUEST_COUNT') {
        state.dataCount = {}
      } else if (action.meta.arg.metric === 'PATH_REQUEST_COUNT') {
        state.dataPathCount = {}
      } else if (action.meta.arg.metric === 'REQUEST_LIST') {
        state.dataList = {}
      } else if (action.meta.arg.metric === 'PATH_REQUEST_LIST') {
        state.dataPathList = {}
      } else if (action.meta.arg.metric === 'REQUEST_CSBYTES') {
        state.dataCSBytes = {}
      } else if (action.meta.arg.metric === 'PATH_REQUEST_CSBYTES') {
        state.dataPathCSBytes = {}
      } else if (action.meta.arg.metric === 'REQUEST_SCBYTES') {
        state.dataSCBytes = {}
      } else if (action.meta.arg.metric === 'PATH_REQUEST_SCBYTES') {
        state.dataPathSCBytes = {}
      } else {
        state.data = {}
      }

      return state
    },
    [getTenantClientWebLogs.pending]: (state, action) => {
      const { meta } = action
      const { arg } = meta
      const { metric } = arg

      state.loading = 'pending'

      if (metric === 'REQUEST_COUNT') {
        state.dataCount[metric] = null
      } else if (metric === 'PATH_REQUEST_COUNT') {
        state.dataPathCount[metric] = null
      } else if (metric === 'REQUEST_LIST') {
        state.dataList[metric] = null
      } else if (metric === 'PATH_REQUEST_LIST') {
        state.dataPathList[metric] = null
      } else if (metric === 'REQUEST_CSBYTES') {
        state.dataCSBytes[metric] = null
      } else if (metric === 'PATH_REQUEST_CSBYTES') {
        state.dataPathCSBytes[metric] = null
      } else if (metric === 'REQUEST_SCBYTES') {
        state.dataSCBytes[metric] = null
      } else if (metric === 'PATH_REQUEST_SCBYTES') {
        state.dataPathSCBytes[metric] = null
      } else {
        state.data[metric] = null
      }

      return state
    },
    [getTenantClientWebLogs.fulfilled]: (state, action) => {
      const { meta, payload } = action
      const { arg } = meta
      const { metric } = arg

      console.log('getTenantClientWebLogs.fulfilled●' + metric)

      state.loading = 'idle'
      if (metric === 'REQUEST_COUNT') {
        state.dataCount[metric] = payload
      } else if (metric === 'PATH_REQUEST_COUNT') {
        state.dataPathCount[metric] = payload
      } else if (metric === 'REQUEST_LIST') {
        state.dataList[metric] = payload
      } else if (metric === 'PATH_REQUEST_LIST') {
        state.dataPathList[metric] = payload
      } else if (metric === 'REQUEST_CSBYTES') {
        state.dataCSBytes[metric] = payload
      } else if (metric === 'PATH_REQUEST_CSBYTES') {
        state.dataPathCSBytes[metric] = payload
      } else if (metric === 'REQUEST_SCBYTES') {
        state.dataSCBytes[metric] = payload
      } else if (metric === 'PATH_REQUEST_SCBYTES') {
        state.dataPathSCBytes[metric] = payload
      } else {
        state.data[metric] = payload
      }

      return state
    },
  },
})

export const selectLoading = (state) => state[SLICE_NAME].loading
export const selectError = (state) => state[SLICE_NAME].error
export const selectClientWebLogUrlById = (state, id) => {
  // console.log(state)
  if (state[SLICE_NAME]) {
    return state[SLICE_NAME].urls[id]
  } else {
    return null
  }
}
export const selectClientWebLogDatabyMetric = (state, metric) => {
  if (state[SLICE_NAME]) {
    // return state[SLICE_NAME].data[metric]
    if (metric === 'REQUEST_COUNT') {
      return state[SLICE_NAME].dataCount[metric]
    } else if (metric === 'PATH_REQUEST_COUNT') {
      return state[SLICE_NAME].dataPathCount[metric]
    } else if (metric === 'REQUEST_LIST') {
      return state[SLICE_NAME].dataList[metric]
    } else if (metric === 'PATH_REQUEST_LIST') {
      return state[SLICE_NAME].dataPathList[metric]
    } else if (metric === 'REQUEST_CSBYTES') {
      return state[SLICE_NAME].dataCSBytes[metric]
    } else if (metric === 'PATH_REQUEST_CSBYTES') {
      return state[SLICE_NAME].dataPathCSBytes[metric]
    } else if (metric === 'REQUEST_SCBYTES') {
      return state[SLICE_NAME].dataSCBytes[metric]
    } else if (metric === 'PATH_REQUEST_SCBYTES') {
      return state[SLICE_NAME].dataPathSCBytes[metric]
    } else {
      return state[SLICE_NAME].data[metric]
    }
  } else {
    return null
  }
}

export const { actions, reducer } = clientWebLogsMetricsSlice

export default reducer
